@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200;400;600&display=swap');

/*Root and Bootstrap customizations*/

body {
    font-family: 'Montserrat', sans-serif;
}

.form-check-input:checked {
    background-color: #5DA0A9;
    border-color: #47669C;
}

a {
    color: #F5F3F5;
}

    a:hover {
        color: #47669C;
    }

/* Custom classes */

.topImg {
    width: 100%;
    min-height: 100%;
    background-color: black;
    background-image: url('images/top.jpg');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}

.topText {
    padding: 6%;
    background-color: #F5F3F5;
}

.topItems {
    line-height: 35px;
}

.imgMap {
    max-height: 500px;
    max-width: 100%;
}

.imgAppear {
    animation: fadeIn 1.2s;
}

@keyframes fadeIn {
    from {
        opacity: 0
    }

    to {
        opacity: 1
    }
}

.imgBorder {
    border-radius: 7px;
    max-width: 100%;
}

.thinText {
    font-weight: 200;
}

.officeNavy {
    background-color: rgba(44,73,107, 1);
    border-radius: 7px;
    color: #F5F3F5;
}

.officeBlue {
    background-color: rgba(71,102,156, 1);
    border-radius: 7px;
    color: #F5F3F5;
}

.officeTurqoise {
    background-color: rgba(93,160,169, 1);
    border-radius: 7px;
    color: #F5F3F5;
}

.office {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    min-height: 450px;
    border-radius: 7px 0 0 7px;
}

.officeBorder {
    border-radius: 7px;
}

@media (max-width: 768px) {
    .office {
        min-height: 300px;
    }
}

.office1 {
    background-image: url('images/office1.jpg');
}

.office1-1 {
    background-image: url('images/office1-1.jpg');
}

.office2 {
    background-image: url('images/office2.jpg');
}

.office2-1 {
    background-image: url('images/office2-1.jpg');
}


.office3 {
    background-image: url('images/office3.jpg');
}

.office3-1 {
    background-image: url('images/office3-1.jpg');
}


.visitBtn {
    background-color: #0E7154;
}

.officeTop {
    margin-top: 5%;
    line-height: 30px;
}

    .officeTop > button {
        margin-top: 35px;
    }

    .officeTop b {
        font-size: 1.2em;
    }

.smallImg{
    max-width:100%;
    max-height:200px;
}

/*Calendar plugin responsive*/

.responsive-calendar {
    /* by setting font-size, all the elements will correspond */
    font-size: 9px !important; /* default to 10px */
    width: 100%;
}

@media (max-width: 1500px) {
    .responsive-calendar {
        font-size: 8px !important;
    }
}

@media (max-width: 1200px) {
    .responsive-calendar {
        font-size: 7px !important;
    }
}

@media (max-width: 768px) {
    .responsive-calendar {
        font-size: 6px !important;
    }
}

/* Large screens */
@media (min-width: 2500px) {
    .responsive-calendar {
        font-size: 12px !important;
    }
}
